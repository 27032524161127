const outOption = {
  title: "电子设备",
  column: [
    {
      label: '设备编号',
      prop: 'deviceNo',
    },
    {
      label: '设备类型',
      prop: 'deviceType',
    },
    {
      label: '设备状态',
      prop: 'status',
    },
    {
      label: "所属部门",
      prop: "deptName",
    },
    {
      label: "最后活动时间",
      prop: "lastReportTime",
    },
    {
      label: "最后上报电量",
      prop: "battery",
    },
  ],
};
export default outOption

import request from "@/utils/request";

// 新增电子围栏
export const addElectronFence = (data)=>{
  return request({
    url: "/electron/fence",
    method: "post",
    data,
  });
}
// 查询电子围栏
export const electronFence = (deviceNo)=>{
  return request({
    url: `/electron/fence/${deviceNo}`,
    method: "get",
  });
}
//删除电子围栏
export const delElectronFence = (deviceNo)=>{
  return request({
    url: `/electron/fence?deviceNo=${deviceNo}&index=1`,
    method: "delete",
  });
}

<template>
  <el-dialog
    title="电子围栏"
    :append-to-body="true"
    :visible.sync="visible"
    top="10vh"
    width="70%"
  >
    <el-button type="primary" @click="clearPolygon" style="margin-bottom: 12px" size="small">清除</el-button>
    <div id="mapLocation"></div>
  </el-dialog>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import {addElectronFence, delElectronFence, electronFence} from "../../../../../api/elec/fence";

export default {
  data() {
    return {
      visible: false,
      map: null,
      AMap: null,
      mouseTool:null,
      polygon:null,
      deviceInfo:{}
    };
  },
  methods: {
    async show(row) {
      this.visible = true;
      this.deviceInfo = row
      let position
      if(row.longitude&&row.latitude){
        position=[row.longitude,row.latitude]
      }else{
        position = [118.08891,24.479627]
      }
      await this.initMap(position);
      if(row.longitude&&row.latitude){
        const marker = new AMap.Marker({
          position: new AMap.LngLat(position[0], position[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        });
        this.map.add(marker);
      }
      await this.getElectronFence(row.deviceNo)
    },
    async getElectronFence(deviceNo){
      const res = await electronFence(deviceNo)
      if(res.data['1']){
        let path = [];
        res.data['1'].forEach(item=>{
          path.push(new AMap.LngLat(item.longitude,item.latitude))
        })
        this.polygon = new AMap.Polygon({
          path: path,
          fillColor: 'red', // 多边形填充颜色
          fillOpacity:'0.5',
          borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: 'red', // 线条颜色
        });
        this.map.add(this.polygon)
      }else{
        this.mouseTool = new AMap.MouseTool(this.map);
        this.mouseTool.polygon({
          fillColor: 'red', // 多边形填充颜色
          fillOpacity:'0.5',
          borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: 'red', // 线条颜色
        });
        this.mouseTool.on('draw',this.draw)
      }
    },
    hide() {
      this.visible = false;
    },
    async clearPolygon(){
      if(this.polygon){
        this.map.remove(this.polygon)
      }
      this.mouseTool = new AMap.MouseTool(this.map);
      this.mouseTool.polygon({
        fillColor: 'red', // 多边形填充颜色
        fillOpacity:'0.5',
        borderWeight: 2, // 线条宽度，默认为 1
        strokeColor: 'red', // 线条颜色
      });
      this.mouseTool.on('draw',this.draw)
      await delElectronFence(this.deviceInfo.deviceNo)
      this.$message.success('清除成功')
    },
    // 初始化地图
    async initMap(position) {
      this.AMap = await AMapLoader.load({
        key: "a0431274a06861ab57ac681244e7d20f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.MouseTool'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      });
      this.map = new this.AMap.Map("mapLocation", {
        //设置地图容器id
        // viewMode:"3D",    //是否为3D地图模式
        zoom: 12, //初始化地图级别
        center: position, //初始化地图中心点位置
        // mapStyle: "amap://styles/darkblue",
      });
      this.map.on("complete", () => {
        console.log("地图加载成功");
      });
    },
    async draw(e){
      const fenceList = []
      const path = e.obj.getPath()
      console.log(path)
      path.forEach(item=>{
        fenceList.push({
          deviceNo:this.deviceInfo.deviceNo,
          idx:1,
          latitude:item.lat,
          longitude:item.lng
        })
      })
      this.polygon = e.obj
      const res = await addElectronFence(fenceList)
      if(res.code===0){
        this.$message.success('新增成功')
      }
      this.mouseTool.close(false)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/avue-table.scss";
#mapLocation {
  width: 100%;
  height: 600px;
}
</style>

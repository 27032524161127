export const tableOption = {
  border: true,
  stripe: true,
  menuAlign: "center",
  searchMenuSpan: 6,
  tip: false,
  editBtn: false,
  selection: true,
  delBtn: false,
  align: "center",
  addBtn: false,
  menu: true,
  column: [
    {
      fixed: true,
      label: "设备编号",
      prop: "deviceNo",
      span: 24,
      rules: [
        {
          required: true,
          message: "请输入设备编号",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "设备类型",
      type: "select",
      prop: "deviceType",
      dicData: [],
      span: 24,
      rules: [
        {
          required: true,
          message: "请选择设备类型",
          trigger: "blur",
        },
      ],
    },
    {
      label: "所属部门",
      prop: "deptId",
      hide: true,
      rules: [
        {
          required: true,
          message: "请选择所属部门",
          trigger: "blur",
        },
      ],
    },
    {
      fixed: true,
      label: "所属部门",
      prop: "deptName",
      addDisplay: false,
      editDisplay: false,
    },
    /*    {
    fixed: true,
    label: '所属人',
    prop: 'elecPerson',
    addDisplay: false,
    span: 24,
  },*/
    {
      fixed: true,
      label: "设备状态",
      prop: "status",
      dicData: [
        {
          label: "离线",
          value: 0,
        },
        {
          label: "在线",
          value: 1,
        },
      ],
      span: 24,
      addDisplay: false,
      editDisplay: false,
    },
    {
      fixed: true,
      label: "最后活动时间",
      prop: "lastReportTime",
      span: 24,
      addDisplay: false,
      editDisplay: false,
    },
    {
      fixed: true,
      label: "最后上报电量",
      prop: "battery",
      span: 24,
      addDisplay: false,
      editDisplay: false,
    },
  ],
};
